import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import ChineseLayout from "../../components/ChineseLayout"
// import Inactivity from "../../components/Inactivity"

/* Basic content page structure */

class CookiesPolicy extends React.Component {
  componentDidMount() {
    // Inactivity()
  }

  render() {
    return (
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - Cookies Policy</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -no-content">

          </section>

          <section className="rich-text -bg-white">
            <nav className="breadcrumb" role="navigation">
              <Link to="/zh" title="首页">
                <i className="fas fa-arrow-left"></i>
                首页
              </Link>
            </nav>
            
            <h1>使用cookie</h1>
  
            <p>我们可以使用一种您的浏览器支持、称为“cookie”的技术将某些信息储存在您的计算机上。cookie用途多样，包括但不限于：帮助您登录（如在您注册或登录时记录您的用户名）；记录您的某些定制用户偏好；帮助我们更好地理解人们如何使用我们的网站；以及帮助我们改善网站。有时候，这些信息可以帮助我们为您量身定制您感兴趣的内容，或使我们无需在您再次访问网站时询问相同的信息。我们网站设定的cookies仅为我们网站之用。如需停用或限制cookie的使用，请参阅您的互联网浏览器所提供的选项。但是，如果您这样做的话，本网站的某些页面可能会无法正常工作。</p>

          </section>
        </main>
      </ChineseLayout>
    )
  }
} 

export default CookiesPolicy